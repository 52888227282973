.onmail-discontinue {
  .background-frame-header {
    height: 60vh;
  }
  .padding-small {
    padding: 32px;
    .discontinue-email {
      margin-top: -16vh;
      margin-bottom: 80px;
      background-color: #fff;
      border-radius: 10px;
      background: #fff;
      padding: 80px;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
      p {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .onmail-discontinue {
    .padding-small {
      .discontinue-email {
        padding: 32px 12px;
      }
    }
  }
}
