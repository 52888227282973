.gradiento {
  background: linear-gradient(-45deg, #23a6d5, #23d5ab);
  background-size: 100% 100%;
  //animation: gradient 15s ease infinite;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
